* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: white;
  transition: background-color 0.2s ease;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #212529;
}

#nprogress .bar {
  background: #adb5bd !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #adb5bd, 0 0 5px #adb5bd !important;
}

#nprogress .spinner-icon {
  border-top-color: #adb5bd !important;
  border-left-color: #adb5bd !important;
}

@media only screen and (min-width: 48rem) {
  html {
    background-color: rgba(0, 0, 0, 0.025);
  }
}
